import React from "react";
import { useForm } from "react-hook-form";

import { Grid } from "@mui/material";

import { FormSelect } from "@fenix/core/controls/FormTypes";
import { FormAutocomplete } from "@fenix/core/controls/FormTypes/FormAutocomplete";
import { FormTextField } from "@fenix/core/controls/FormTypes/FormTextField";
import { LoaderWrapper } from "@fenix/core/components/LoaderWrapper/LoaderWrapper";
import { isNotEmpty, onInvalidForm } from "@fenix/core/helpers/tools";
import { FormPopup } from "@fenix/core/components/Popup/FormPopup";

import { getUserPermissionGroups, getUserProfiles, saveUser, updateUser } from "./users.api";

export const AddEditUser = ({ popup, action, item }) => {
  const [user] = React.useState(item);
  const [permissionGroups, setPermissionGroups] = React.useState(null);
  const [profiles, setProfiles] = React.useState(null);

  React.useEffect(() => {
    (async () => setPermissionGroups(await getUserPermissionGroups(item)))();
  }, [item]);

  React.useEffect(() => {
    (async () => setProfiles(await getUserProfiles(item)))();
  }, [item]);

  const onSubmit = async (data) => {
    let modified = { ...user, ...data };

    if (modified.profileId != null) {
      if (modified.profileId.length == 0) {
        modified.profileId = null;
      } else if (Array.isArray(modified.profileId)) {
        modified.profileId = modified.profileId[0];
      }
    }

    modified.permissionGroupIds = modified.permissionGroups?.map((x) => x.id) ?? [];

    const result = item ? await updateUser(modified) : await saveUser(modified);

    return result.error ? result : { message: item != null ? "Dane użytkownika zostały zmienione" : "Użytkownik został dodany" };
  };

  const form = useForm({ shouldFocusError: true });
  const emailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  return (
    <LoaderWrapper isLoading={!permissionGroups || !profiles}>
      {permissionGroups && profiles && (
        <FormPopup popup={popup} form={form} onError={() => onInvalidForm()} onSubmit={onSubmit}>
          <Grid container direction="column" spacing={2}>
            <FormTextField
              form={form}
              disabled={user ? true : false}
              name={"email"}
              label={"Email"}
              required={true}
              maxLength={50}
              rules={{
                required: "Podaj email",
                pattern: { value: emailPattern, message: "Niepoprawny format adresu email" },
              }}
              value={user?.login}
            />
            <FormAutocomplete
              multiple={true}
              form={form}
              name={"permissionGroups"}
              options={permissionGroups}
              label={"Grupy uprawnień"}
              required={true}
              value={permissionGroups.filter((x) => x.isAssigned === true)}
              rules={{ required: "Podaj uprawnienie" }}
              xs={11}
              sm={11}
            />
            <FormSelect
              xs={12}
              sm={12}
              form={form}
              name={"profileId"}
              label={"Profil danych"}
              type="select"
              items={profiles.map((item) => ({
                id: item.id,
                name: item.name,
              }))}
              value={profiles.filter((x) => x.isSelected === true).map((x) => x.id) ?? null}
              required={false}
            />
          </Grid>
        </FormPopup>
      )}
    </LoaderWrapper>
  );
};
