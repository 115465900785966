import { patchData, postData, getData } from "@fenix/core/services/api";

export const authenticate = async (login, password, clientInfo) =>
  await postData("/security/authenticate", { login, password, clientInfo });

export const unauthorize = async () => await postData("/security/unauthorize");

export const resetPasswordInit = async (email) =>
  await patchData("/security/password/reset-init", { email });

export const resetPasswordConfirm = async (
  password,
  userToken,
  operationToken
) =>
  await patchData("/security/password/reset-confirm", {
    password,
    userToken,
    operationToken,
  });

export const changePassword = async (oldPassword, newPassword) =>
  await patchData("/security/password/change", { oldPassword, newPassword });

export const activateAccount = async (data) => await postData("/security/activate-account", data);

export const verifyActivateAccountToken = async (userId, token) =>
  await getData(`/security/verify-activate-account-token?userId=${encodeURIComponent(userId)}&token=${encodeURIComponent(token)}`);

export const generateSsoCode = async (clientOrigin) => await postData("/security/generate-sso-code", { clientOrigin: clientOrigin });