import React, { useCallback, useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import { DataView, stringColumn, lookupColumn } from "@fenix/core/controls/DataView/DataView";
import { GridActions, RowActions } from "@fenix/core/controls/DataView/Actions";
import { AddEditUser } from "./AddEditUser";
import { getUsers, deleteUser, sendActivateAccountEmail, userStatusLookups } from "./users.api";
import { useSnackbar } from "notistack";

const Users = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [userStatuses, setUserStatuses] = useState([]);

  useEffect(() => {
    (async () => { setUserStatuses(await userStatusLookups()); })();
  }, [setUserStatuses]);

  const onSendActivateAccountEmail = useCallback((arg) => {
    (async () => { 
      await sendActivateAccountEmail(arg.item.id);
      enqueueSnackbar("Email aktywacyjny został pomyślnie wysłany", { variant: "success" });
  })();
  });

  const deleteUserItem = useCallback(async (item) => {
    await deleteUser(item.id);
  }, []);

  return (
    <DataView
      title="PRACOWNICY"
      columns={[
        { field: "id", hide: true, filterable: false },
        { field: "login", headerName: "Email", ...stringColumn, flex: 1.5 },
        { field: "firstName", headerName: "Imię", ...stringColumn, flex: 1 },
        { field: "status", headerName: "Status", ...lookupColumn(userStatuses), flex: 1 },
        { field: "lastName", headerName: "Nazwisko", ...stringColumn, flex: 1.5 },
        {
          field: "groups",
          headerName: "Grupy uprawnień",
          ...stringColumn,
          sortable: false,
          renderCell: value => (
            <Typography sx={{ whiteSpace: "pre-line", fontSize: 14 }}>{value.row.groups}</Typography>
          ),
          width: 300
        }
      ]}
      actions={[
        {
          type: "rowMulti",
          flex: 1,
          label: "Akcje",
          actions: [
            // RowActions.PreviewDetails((arg) => <AddEditUser {...arg} readOnly={true} />, 'Dashboard/User/Details'),
            RowActions.EmailForwardTo(
              "Wyślij ponownie link aktywacyjny",
              "Wyślij ponownie link aktywacyjny",
              onSendActivateAccountEmail,
              'Dashboard/User/Add',
              (row) => { return row.status === 0; }),
              RowActions.Edit('Edycja użytkownika', (arg) => <AddEditUser {...arg} />, 'Dashboard/User/Edit'),
              RowActions.Delete('Usunięcie danych użytkownika', (item) => `Czy na pewno chcesz usunąć użytkownika '${item.firstName}'?`, deleteUserItem, 'Dashboard/User/Delete')
          ]
        },
        GridActions.Add('Dodaj użytkownika', 'Dodanie nowego użytkownika', (arg) => <AddEditUser {...arg} />, 'Dashboard/User/Add')
      ]}
      loadData={(filter, sort, limit) => getUsers(filter, sort, limit)}
    ></DataView>
  );
};

export default Users;
