import { TextField } from "@mui/material";

import { TooltipWrapper } from "../TooltipWrapper/TooltipWrapper";
import FormWrapper from "./FormWrapper";

/**
 *
 * @param props.type typ pola input (valid HTML5 input type).
 * @param props.control dla walidacji
 * @param props.name - nazwa pola
 * @param props.label - opis pola
 * @param props.required - czy wymagane
 * @param props.variant - typ graficzny dostępne opcje: filled | outlined | standard
 * @param props.xs - szerokość pola w grid przy mobile
 * @param props.sm - szerokość pola w grid przy tablet
 * @param props.md - szerokość pola w grid przy desktop
 * @param props.rules - warunki walidacji
 *
 * @returns pole tekstowe typu input
 */
export const FormTextField = props => {
  return (
    <FormWrapper
      props={props}
      xs={props.xs}
      sm={props.sm}
      md={props?.md}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TooltipWrapper text={props.helperText}>
          <TextField
            autoComplete={props.autoComplete}
            InputLabelProps={{ shrink: props.disabled ? true : undefined }}
            name={props.name}
            id={props.id ?? props.name}
            label={props.label}
            placeholder={props.placeholder}
            required={props.required}
            variant={props.variant ?? "outlined"}
            fullWidth
            value={value ?? ""}
            onChange={e => {
              const text = props.trim ? e.target.value.trim() : e.target.value;
              const newValue = props.returnNumber ? parseInt(text) : props?.lowercase ? text?.toLowerCase() : text;
              onChange(newValue);
              if (props.onChange) props.onChange(newValue);
            }}
            disabled={props.disabled}
            error={!!error}
            helperText={error ? error.message : " "}
            multiline={props.multiline}
            minRows={props.minRows}
            type={props.type}
            step={props.step}
            inputProps={{
              maxLength: props.maxLength,
              style: props.style
            }}
            inputRef={ref}
            autoFocus={props.autoFocus}
            className={props.className}
          />
        </TooltipWrapper>
      )}
    />
  );
};

export default FormTextField;
